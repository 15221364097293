var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("LIQUIDACIONES")]),_c('app-table-registers',{ref:"tr",attrs:{"exeGetChildAfterMount":true,"getList":_vm.getList}},[_c('template',{slot:"top"},[_c('span')]),_c('template',{slot:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("PROFESIONAL")]),_c('th',[_vm._v("REALIZADO")]),_c('th',[_vm._v("POR PAGAR")]),_c('th',[_vm._v("DETALLE")]),_c('th')])]),_c('tbody',_vm._l((_vm.list.data),function(l){return _c('tr',{key:l.id},[_c('td',[_vm._v(_vm._s(l.name))]),_c('td',[_c('app-span-money',{attrs:{"quantity":l.amount_maked,"moneyCode":1}})],1),_c('td',[_c('app-span-money',{attrs:{"quantity":l.amount_maked,"moneyCode":1}})],1),_c('td',[_c('router-link',{attrs:{"to":{
                    path: ("/dental-clinic/liquidations/payments/" + (l.id)),
                    query: {
                      paid_out: 0
                    }
                  }}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" Ver ")])],1),_c('td',[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-sm btn-warning dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Finalizar ")]),_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.employee_id = l.id;
                        _vm.setDateLimit = false;
                        _vm.$refs.elQuePrevLiq.show();}}},[_vm._v("Finalizar hasta hoy")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.employee_id = l.id;
                        _vm.setDateLimit = true;
                        _vm.$refs.elQuePrevLiq.show();}}},[_vm._v("Finalizar hasta una fecha")])])])])])}),0)])],2)],1)]),_c('app-modal-yn',{ref:"elQuePrevLiq",on:{"yes":function($event){return _vm.goToLiquid()}}},[_c('p',[_vm._v("Proceder?")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.setDateLimit),expression:"setDateLimit"}],staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha limite")]),_c('app-datepicker',{model:{value:(_vm.dateLimit),callback:function ($$v) {_vm.dateLimit=$$v},expression:"dateLimit"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
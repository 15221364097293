<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>LIQUIDACIONES</h4>
        <app-table-registers
          :exeGetChildAfterMount="true"
          ref="tr"
          :getList="getList"
        >
          <template slot="top">
            <span></span>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <!-- <th>U. COD</th> -->
                <th>PROFESIONAL</th>
                <!-- <th>PORCENTAJE(%)</th> -->
                <th>REALIZADO</th>
                <th>POR PAGAR</th>
                <th>DETALLE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in list.data" :key="l.id">
                <!-- <td>{{ l.id }}</td> -->
                <td>{{ l.name }}</td>
                <!-- <td>{{ l.portion_by_service }}%</td> -->
                <td>
                  <app-span-money
                    :quantity="l.amount_maked"
                    :moneyCode="1"
                  ></app-span-money>
                </td>
                <td>
                  <app-span-money
                    :quantity="l.amount_maked"
                    :moneyCode="1"
                  ></app-span-money>
                  <!-- <app-span-money
                    :quantity="l.amount_to_pay"
                    :moneyCode="1"
                  ></app-span-money> -->
                </td>
                <td>
                  <!-- <button
                    class="btn btn-light btn-sm"
                    @click="
                      $refs.dEmpPays.show();
                      $refs.payments.setEmployeeId(l.id);
                    "
                  >
                    <i class="fa fa-search"></i> Ver
                  </button> -->
                  <router-link
                    :to="{
                      path: `/dental-clinic/liquidations/payments/${l.id}`,
                      query: {
                        paid_out: 0
                      }
                    }"
                    ><i class="fa fa-search"></i> Ver
                  </router-link>
                </td>
                <td>
                  <!-- Example single danger button -->
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-warning dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Finalizar
                    </button>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item"
                        href="#"
                        @click.prevent="
                          employee_id = l.id;
                          setDateLimit = false;
                          $refs.elQuePrevLiq.show();
                        "
                        >Finalizar hasta hoy</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click.prevent="
                          employee_id = l.id;
                          setDateLimit = true;
                          $refs.elQuePrevLiq.show();
                        "
                        >Finalizar hasta una fecha</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <!--
    <app-modal-basic ref="dEmpPays" :xl="true">
      <h4>PAGOS</h4>
      <Payments ref="payments"></Payments>
    </app-modal-basic>
    -->

    <app-modal-yn ref="elQuePrevLiq" @yes="goToLiquid()">
      <p>Proceder?</p>
      <div class="form-group" v-show="setDateLimit">
        <label for="">Fecha limite</label>
        <app-datepicker v-model="dateLimit"></app-datepicker>
      </div>
    </app-modal-yn>
  </div>
</template>
<script>
// import { DentalClinicService } from "../service";
// import FormConfig from "./Form";
// import Horary from "../../admin-module/employees/Horary";
//import Payments from "./Payments";
// import navbar from "./Navbar";
import { GeneralService } from "../../general-module/GeneralService";
import { myMoment, momentDateFormat } from "../../utils/myMoment";
import { AdminService } from "../../admin-module";
import { mapState } from "vuex";

export default {
  components: {
    // FormConfig,
    // Horary,
    //Payments,
    // navbar
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: {},
    employee_id: undefined,
    setDateLimit: false,
    dateLimit: new Date(),
    date: {}
  }),

  computed: {
    ...mapState({
      specialty_id: s => s.config.dental.treatments.specialty_id
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reload() {
      this.$refs.tr.gl();
    },
    goToLiquid() {
      GeneralService.saveEmployeeLiquidation({
        // make_from_payments: 1,
        employee_id: this.employee_id,
        end_date: myMoment(this.setDateLimit ? this.dateLimit : undefined)
          // .add(1, "days")
          .format(momentDateFormat)
      }).then(id => {
        this.$router.push({
          path: `/dental-clinic/liquidations/payments/${this.employee_id}`,
          query: {
            employee_liquidation_id: id
          }
        });

        // this.$router.push({
        //   path: "/dental-clinic/liquidations/maked"
        // });
      });
    },
    getList(params) {
      return new Promise(rsv => {
        AdminService.getEmployees({
          ...params,
          ...this.date,
          specialties: this.specialty_id
        }).then(res => {
          res.data = res.data.map(x => ({
            ...x,
            portion_by_service: x.portion_by_service ? x.portion_by_service : 0,
            interval_service: x.interval_service ? x.interval_service : 0
          }));
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>


